import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./modules/login/authActions";
import { Provider } from "react-redux";
import store from "./store";

import Navbar from "./modules/layout/components/Navbar";
import Register from "./modules/login/components/Register";
import Login from "./modules/login/components/Login";
import LeerQr from "./modules/secuestros/components/LeerQr";
import PrivateRoute from "./modules/private-route/PrivateRoute";
import Dashboard from "./modules/layout/components/Dashboard";

import "./App.css";
import IngresarVehiculo from "./modules/secuestros/components/IngresarVehiculo";
import IngresoPolicial from "./modules/secuestros/components/IngresoPolicial";
import VerVehiculo from "./modules/secuestros/components/VerVehiculo";
import VehiculosIndex from "./modules/secuestros/components/VehiculosIndex";
import UsuariosIndex from "./modules/usuarios/components/UsuariosIndex";
import Footer from "./modules/layout/components/Footer";
import BuscarVehiculo from "./modules/secuestros/components/BuscarVehiculo";
import ResetearUsuario from "./modules/usuarios/components/ResetearUsuario";
import PanelVehiculos from "./modules/secuestros/components/PanelVehiculos";
import ResultadosDominio from "./modules/secuestros/components/ResultadosDominio";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoute exact path="/vehiculos" component={VehiculosIndex} />
              <PrivateRoute exact path="/leer_qr" component={LeerQr} />
              <PrivateRoute exact path="/ingreso" component={IngresarVehiculo} />
              <PrivateRoute exact path="/ingreso_policial" component={IngresoPolicial} />
              <PrivateRoute exact path="/ver" component={VerVehiculo} />
              <PrivateRoute exact path="/buscar" component={BuscarVehiculo} />
              <PrivateRoute exact path="/resultados" component={ResultadosDominio} />
              <PrivateRoute exact path="/usuarios" component={UsuariosIndex} />
              <PrivateRoute exact path="/register" component={Register} />
              <PrivateRoute exact path="/resetear" component={ResetearUsuario} />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/stock" component={PanelVehiculos} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;