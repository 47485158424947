import { combineReducers } from "redux";
import authReducer from "./login/authReducer";
import errorReducer from "./errores/errorReducer";
import secuestrosReducer from "./secuestros/secuestrosReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  secuestros: secuestrosReducer
});
