import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Item, Button, Pagination, Icon } from 'semantic-ui-react';
import UIMessage from '../../../components/Message'
import { getSecuestros } from "../../secuestros/secuestrosActions";
import {isDesktop, isMobile, isTablet} from 'react-device-detect';

const moment = require('moment')

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total: null,
      secuestros: [],
      activePage: 1,
      boundaryRange: 1,
      siblingRange: 1,
      showEllipsis: true,
      showFirstAndLastNav: true,
      showPreviousAndNextNav: true,
    };
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }
  msAFecha(ms) {
    return moment(ms).format('DD-MM-YYYY HH:mm')
  }
  componentDidMount() {
    if(this.props.location.state){
      if(this.props.location.state.activePage){
        this.setState({activePage: this.props.location.state.activePage})
        this.props.getSecuestros(this.props.location.state.activePage)
      }
    } else {
      this.props.getSecuestros(this.state.activePage)
    }
  }
  verSecuestro(secuestro) {
    this.props.history.push({ pathname: "/ver", state: { secuestro: secuestro, activePage: this.state.activePage } })
  }
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage })
    this.props.getSecuestros(activePage)
  }
  render() {
    const { user } = this.props.auth;
    const { secuestros, total, loading } = this.props.secuestros;
    const {
      activePage,
      boundaryRange,
      siblingRange,
      showEllipsis,
      showFirstAndLastNav,
      showPreviousAndNextNav
    } = this.state
    return (
      <div>
        <Button.Group widths='2'>
        <Button primary onClick={() => this.props.history.push({ pathname: "/ingreso" })}>Nuevo ingreso</Button>
        <Button secondary onClick={() => this.props.history.push({ pathname: "/ingreso_policial" })}>Nuevo oficio policial</Button>
        </Button.Group>
        <Grid celled>
          <Grid.Row>
            <Grid.Column>
              <b>Hola {user.apynom.split(" ")[0]}</b>, estos son los últimos ingresos.
              {loading === true &&
                <UIMessage tipo='cargando' mensaje='Cargando los secuestros' />
              }
              <Grid mobile={16} tablet={5} computer={4} celled>
                <Grid.Column width={isMobile?'16':isTablet?'8':isDesktop?'5':''}>
                  <Item.Group divided link>
                    {!loading && secuestros.length > 0 && secuestros.map((secuestro, index) => (isMobile && index<24)||(isTablet && index<12)||(isDesktop && index<8)?
                      <Item key={secuestro._id} onClick={() => this.verSecuestro(secuestro)}>
                        <Item.Image size='tiny' src={'/api' + secuestro.foto.replace('.png', '.jpg')} />
                        <Item.Content>
                          <Item.Header as='a'>{secuestro.vehiculo.tipovh} {secuestro.vehiculo.dominio?secuestro.vehiculo.dominio.toUpperCase():'Sin dominio'}</Item.Header>
                          <Item.Meta>Estado: {secuestro.egreso ? <b>Egresado</b> : secuestro.compactado ? <b>Compactado</b>:<b>Ingresado</b>} {secuestro.acta ? `N° acta: ${secuestro.acta.nro}`:''}</Item.Meta>
                          {secuestro.acta ? <Item.Description>
                              {secuestro.acta.lugar}
                            </Item.Description>:''}
                          
                          <Item.Extra>{this.msAFecha(secuestro.fecha_hora)}</Item.Extra>
                        </Item.Content>
                      </Item>:''
                    )
                    }
                </Item.Group>
                  </Grid.Column> 
                  {isDesktop||isTablet?<Grid.Column width='5'>
                  <Item.Group divided link>
                    {!loading && secuestros.length > 0 && secuestros.map((secuestro, index) => !isMobile&&((isTablet && index<24)||(isDesktop && index>=8 && index<16))?
                      <Item key={secuestro._id} onClick={() => this.verSecuestro(secuestro)}>
                        <Item.Image size='tiny' src={'/api' + secuestro.foto.replace('.png', '.jpg')} />
                        <Item.Content>
                          <Item.Header as='a'>{secuestro.vehiculo.tipovh} {secuestro.vehiculo.dominio?secuestro.vehiculo.dominio.toUpperCase():'Sin dominio'}</Item.Header>
                          <Item.Meta>Estado: {secuestro.egreso ? <b>Egresado</b> : secuestro.compactado ? <b>Compactado</b>:<b>Ingresado</b>} {secuestro.acta ? `N° acta: ${secuestro.acta.nro}`:''}</Item.Meta>
                            {secuestro.acta ? <Item.Description>
                              {secuestro.acta.lugar}
                            </Item.Description>:''}
                          
                          <Item.Extra>{this.msAFecha(secuestro.fecha_hora)}</Item.Extra>
                        </Item.Content>
                      </Item>
                    :'')
                    }
                  </Item.Group>
                  </Grid.Column>:''}
                  {isDesktop?<Grid.Column width='5'>
                  <Item.Group divided link>
                    {!loading && secuestros.length > 0 && secuestros.map((secuestro, index) => !isMobile&&!isTablet&&(isDesktop && index>=16 && index<=24)?
                      <Item key={secuestro._id} onClick={() => this.verSecuestro(secuestro)}>
                        <Item.Image size='tiny' src={'/api' + secuestro.foto.replace('.png', '.jpg')} />
                        <Item.Content>
                          <Item.Header as='a'>{secuestro.vehiculo.tipovh} {secuestro.vehiculo.dominio?secuestro.vehiculo.dominio.toUpperCase():'Sin dominio'}</Item.Header>
                          <Item.Meta>Estado: {secuestro.egreso ? <b>Egresado</b> : secuestro.compactado ? <b>Compactado</b>:<b>Ingresado</b>} {secuestro.acta ? `N° acta: ${secuestro.acta.nro}`:''}</Item.Meta>
                            {secuestro.acta ? <Item.Description>
                              {secuestro.acta.lugar}
                            </Item.Description>:''}
                          <Item.Extra>{this.msAFecha(secuestro.fecha_hora)}</Item.Extra>
                        </Item.Content>
                      </Item>
                    :'')
                    }
                  </Item.Group>
                  </Grid.Column>:''}
              </Grid>
              <Item.Group>
              {!loading && secuestros.length > 0 &&
                  <Pagination
                    activePage={activePage}
                    boundaryRange={boundaryRange}
                    onPageChange={this.handlePaginationChange}
                    siblingRange={siblingRange}
                    size={isMobile||isTablet?'tini':'huge'}
                    totalPages={Number.isInteger(total / 10) ? total / 10 : parseInt(total / 10) + 1}
                    ellipsisItem={showEllipsis ? { content: <Icon name='ellipsis horizontal' />, icon: true } : null}
                    firstItem={showFirstAndLastNav ? { content: <Icon name='angle double left' />, icon: true } : null}
                    lastItem={showFirstAndLastNav ? { content: <Icon name='angle double right' />, icon: true } : null}
                    prevItem={showPreviousAndNextNav ? { content: <Icon name='angle left' />, icon: true } : null}
                    nextItem={showPreviousAndNextNav ? { content: <Icon name='angle right' />, icon: true } : null}
                  />
                }
              {secuestros.length === 0 &&
                  <b>No hay secuestros registrados</b>
                }
              </Item.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  secuestros: PropTypes.object.isRequired,
  getSecuestros: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth, secuestros }) => ({
  auth, secuestros
});

export default connect(
  mapStateToProps,
  { getSecuestros }
)(Dashboard);
