import React, {Component} from "react";
import Axios from "axios";
import { List, Grid, Modal, Form, Button, Icon, Header } from 'semantic-ui-react'
class ResetearUsuario extends Component {
    constructor(props){
        super(props)
        this.state = {
            usuarios: [],
            modal:false,
            usuario:null,
            cargando:0,
            password:null,
            password2:null
        };
        this.abrirModal = this.abrirModal.bind(this);
        this.cerrarModal = this.cerrarModal.bind(this);
        this.cambiarContra = this.cambiarContra.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount(){
        Axios.get("/api/users/todos")
        .then(
            res => {
                this.setState({usuarios:res.data})
            }
        )
    }
    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
        console.log(event.target.value.toString())
    }
    cerrarModal(){
        this.setState({modal:false})
    }
    abrirModal(usuario){
        this.setState({modal:true, usuario:usuario})
    }
    cambiarContra(){
        if(this.state.password && this.state.password2){
            if(this.state.password === this.state.password2){
                var reseteo = {dni:this.state.usuario.dni, contra:this.state.password}
                Axios.post('/api/users/reseteo', reseteo)
                        .then(
                            res => {
                                alert(res.data)
                                this.setState({password:null, password2:null})
                                this.cerrarModal()
                            }
                        );
            } else {
                alert("Las contraseñas no coinciden, por favor escriba bien la nueva contraseña y repitala.")
            }
        } else {
            alert("Por favor complete los campo Nueva contraseña y Repita la contraseña.")
        }
    }

    render(){
        const { usuarios, usuario, cargando } = this.state
        return(
            <div>
            <Grid celled>
                <Grid.Row>
                    <Grid.Column>
                        <List divided relaxed>
                        {usuarios.map((usuario) =>
                            <List.Item onClick={() => this.abrirModal(usuario)}>
                                <List.Icon name='user' size='large' verticalAlign='middle'/>
                                <List.Content>
                                    <List.Header as='a'>{usuario.apynom}</List.Header>
                                    <List.Description as='a'>Tipo: {usuario.tipo}, registrado: {usuario.date}</List.Description>
                                </List.Content>
                            </List.Item>
                        )}
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {usuario &&
            <Modal
            open={this.state.modal}
            onClose={this.cerrarModal}
            size='fullscreen'
            centered={false}
            closeIcon>
                <Header icon='sign-out' content={'Reseteo de contraseña para ' + usuario.apynom} />
                <Modal.Content scrolling>
                    <Form>
                    <Form.Field>
                        <Form.Input label='Nueva contraseña' type='text' onChange={this.handleChange} id="password" />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label='Repita la contraseña' type='text' onChange={this.handleChange} id="password2" />
                    </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {cargando === 1 ? 
                    <Button loading basic>Cambiando contraseña, por favor espere...</Button>
                    :
                    <Button basic onClick={() => {this.cambiarContra()}}>Cambiar contraseña</Button>
                    }
                    <Button color='red' onClick={this.cerrarModal} inverted>
                        <Icon name='remove' /> Cancelar
                    </Button>
                </Modal.Actions>
            </Modal>
            }
            </div>
        );
    }
}
export default ResetearUsuario;