import React, { Component } from "react";
import { Message } from 'semantic-ui-react';
import { Link } from "react-router-dom";
class UsuariosIndex extends Component {
    render() {
        return (
            <div>
                <Link to="/register">
                    <Message
                        icon='sign-in alternate'
                        header='Nuevo usuario'
                        content='Registra un nuevo usuario'
                    />
                </Link>
                <br></br>
                <Link to="/resetear">
                    <Message
                        icon='eye'
                        header='Resetear usuario'
                        content='Resetea la contraseña del usuario'
                    />
                </Link>
            </div>
        );
    }
}
export default UsuariosIndex;