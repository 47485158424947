import React, { Component } from "react";
import { Segment, Form, Button, Divider } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import Axios from 'axios';
import UIMessage from '../../../components/Message';

const validator = require('validator')
class BuscarVehiculo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            acta: "",
            inventario: "",
            cargando: 0,
            error: "",
            patente: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.buscarInventario = this.buscarInventario.bind(this);
        this.buscarPatente = this.buscarPatente.bind(this);
        this.buscarActa = this.buscarActa.bind(this);
        this.volver = this.volver.bind(this);
    }
    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }
    buscarActa(event) {
        event.preventDefault();
        if (validator.isInt(this.state.acta)) {
            this.setState({ cargando: 1 })
            Axios.get("/api/secuestros/buscar/acta/" + this.state.acta, { headers: { 'token': localStorage.getItem('jwtToken') } })
                .then(res => {
                    this.setState({ cargando: 0 })
                    if(!res.data.error) {
                        this.props.history.push({ pathname: "/ver", state: { secuestro: res.data } })
                    } else {
                        this.setState({ error: res.data.error })
                    }
                })
                .catch(err => alert(err))
        } else {
            this.setState({ error: "Por favor ingrese un número de acta para buscar." })
        }
    }
    buscarInventario(event) {
        event.preventDefault();
        if (validator.isInt(this.state.inventario)) {
            this.setState({ cargando: 1 })
            Axios.get("/api/secuestros/buscar/inventario/" + this.state.inventario, { headers: { 'token': localStorage.getItem('jwtToken') } })
                .then(res => {
                    this.setState({ cargando: 0 })
                    if (!res.data.error) {
                        this.props.history.push({ pathname: "/ver", state: { secuestro: res.data } })
                    } else {
                        this.setState({ error: res.data.error })
                    }
                })
                .catch(err => alert(err))
        } else {
            this.setState({ error: "Por favor ingrese un número de inventario para buscar." })
        }
    }
    buscarPatente(event){
        event.preventDefault();
        if (!validator.isEmpty(this.state.patente)) {
            this.setState({ cargando: 1 })
            Axios.get("/api/secuestros/buscar/patente/" + this.state.patente, { headers: { 'token': localStorage.getItem('jwtToken') } })
                .then(res => {
                    this.setState({ cargando: 0 })
                    if (!res.data.error) {
                        console.log(res.data)
                        this.props.history.push({ pathname: "/resultados", state: { secuestros: res.data } })
                    } else {
                        this.setState({ error: res.data.error })
                    }
                })
                .catch(err => alert(err))
        } else {
            this.setState({ error: "Por favor ingrese una patente para buscar." })
        }
    }
    volver() {
        this.setState({ error: 0, acta: "", inventario: "" })
    }
    render() {
        const { cargando, error } = this.state
        return (
            <div>
                <Segment basic textAlign='center'>
                    {error ?
                        <div>
                            <UIMessage tipo='error' mensaje={this.state.error} />
                            <Button primary fluid onClick={this.volver}>Volver a Buscar</Button>
                        </div>
                        :
                        cargando ?
                            <UIMessage tipo='cargando' mensaje='Recuperando datos del secuestro' />
                            :
                            <div>
                                <Form>
                                    <Form.Field>
                                        <Form.Input label="Patente" value={this.state.patente} onChange={this.handleChange} id="patente" />
                                        <Form.Button basic onClick={this.buscarPatente}>Buscar por patente</Form.Button>
                                    </Form.Field>
                                </Form>
                                <Divider horizontal>O</Divider>
                                <Form>
                                    <Form.Field>
                                        <Form.Input label="N° acta" value={this.state.acta} onChange={this.handleChange} id="acta" />
                                        <Form.Button basic onClick={this.buscarActa}>Buscar por acta</Form.Button>
                                    </Form.Field>
                                </Form>
                                <Divider horizontal>O</Divider>
                                <Form>
                                    <Form.Field>
                                        <Form.Input label="N° inventario" value={this.state.inventario} onChange={this.handleChange} id="inventario" />
                                        <Form.Button basic onClick={this.buscarInventario}>Buscar por inventario</Form.Button>
                                    </Form.Field>
                                </Form>
                            </div>
                    }
                </Segment>
            </div>
        );
    }
}
export default withRouter(BuscarVehiculo);