import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Form, Select, Image, Grid, Card, Step, Icon, Segment, Message, Header, Divider } from "semantic-ui-react";
import { Gmaps, Marker, InfoWindow, Circle } from 'react-gmaps';
import { getSecuestros } from '../secuestrosActions';
import Axios from "axios";
import Camera, {IMAGE_TYPES, FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
const moment = require('moment')
const Validator = require("validator");

class IngresarVehiculo extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            idacta: "",
            repetido: false,
            datosActa: {},
            paso: "1",
            sector: "",
            inventario: "",
            fechahora: "",
            usuario: "",
            error: "",
            cargando: "",
            infractor: {},
            infraccion: {},
            data: {},
            cords: {
                lat: -33.33847333333333,
                lng: -60.228716666666664
            },
            foto: "",
            sectores: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.primerPasoSubmit = this.primerPasoSubmit.bind(this);
        this.segundoPasoSubmit = this.segundoPasoSubmit.bind(this);
        this.tercerPasoSubmit = this.tercerPasoSubmit.bind(this);
        this.cuartoPasoSubmit = this.cuartoPasoSubmit.bind(this);
        this.getInfractor = this.getInfractor.bind(this);
        this.imprimir = this.imprimir.bind(this);
        this.sacarOtraFoto = this.sacarOtraFoto.bind(this);
    }

    componentDidMount() {
        let ahora = moment().format("YYYY-MM-DDTHH:mm")
        this.setState({fechahora: ahora})
    }
    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleDropdownChange = (e, { name, value }) => this.setState({ [name]: value })
    sacarCuil(dni, sexo) {
        var inicio;
        var multiplicadores;
        var calculo;
        var resto;
        var final = 0;

        if (dni.length === 7) { dni = "0" + dni }
        if (sexo === "Masculino") { inicio = "20" } else { inicio = "27" }

        multiplicadores = [3, 2, 7, 6, 5, 4, 3, 2];
        calculo = ((parseInt(inicio.charAt(0)) * 5) + (parseInt(inicio.charAt(1)) * 4));
        for (var i = 0; i < 8; i++) {
            calculo += (parseInt(dni.charAt(i)) * multiplicadores[i]);
        }
        resto = calculo % 11;
        switch (resto) {
            case 0:
                final = "0";
                break
            case 1:
                if (sexo === "Masculino") { final = "9"; } else { final = "4"; }
                inicio = "23";
                break
            default:
                final = 11 - resto;
                break;
        }
        var resultado = inicio.toString() + '-' + dni.toString() + '-' + final.toString();
        return resultado;
    }

    buscarActa(event) {
        //event.preventDefault();
        if (Validator.isInt(this.state.idacta)) {
            this.setState({ cargando: 1 })
            Axios.get("/api/secuestros/buscar/acta/" + this.state.idacta, { headers: { 'token': localStorage.getItem('jwtToken') } })
                .then(res => {
                    this.setState({ cargando: 0 })
                    if(!res.data.error) {
                        this.props.history.push({ pathname: "/ver", state: { secuestro: res.data } })
                    } else {
                        this.setState({ error: res.data.error })
                    }
                })
                .catch(err => alert(err))
        } else {
            this.setState({ error: "Por favor ingrese un número de acta para buscar." })
        }
    }

    primerPasoSubmit(event) {
        event.preventDefault();
        //Antes de pedir los datos del acta a api_rest2.php, validamos que idacta no sea vacio, que sea entero
        //que la respuesta no sea null y que sea un JSON
        if (!Validator.isEmpty(this.state.idacta)) {
            if (Validator.isInt(this.state.idacta)) {
                this.setState({ 'cargando': 1 })
                axios.get("/api/secuestros/buscar/acta/" + this.state.idacta, { headers: { 'token': localStorage.getItem('jwtToken') } })
                    .then(res1 => {
                        if (res1.data.error || res1.data === '' || res1.data === []) {
                            console.log(res1.data)
                            //O sea que tiro el error de que no se encontro el acta ya ingresada, entonces OK
                            //https://www.sannicolas.gov.ar/intranet/corralon/api_rest2.php?acta=
                            axios.get("https://actas.movisn.com/transito/actaByNro/" + this.state.idacta)
                                .then(res => {
                                    this.setState({ 'cargando': 0 })
                                    if (res.data != null) {

                                        let cords = { lat: 0, lng: 0 }

                                        let data = res.data[0]

                                        if(data.coordenadas){
                                            let puntos = data.coordenadas.split(";")
                                            cords = { lat: puntos[0], lng: puntos[1] }
                                        }
                                        
                                        let infraccion = data.infraccion_txt || data.infraccion

                                        this.setState({ datosActa: data , paso: "2", error: "", 
                                        data: { 
                                            dominio : data.vh_dominio || "",
                                            tipovh : data.vh_tipo || "",
                                            marcavh : data.vh_marca || "",
                                            modelovh : data.vh_modelo || "",
                                        }, 
                                        infraccion: infraccion, cords: cords });

                                        this.getInfractor({ id : data.infractor});
                                        /*
                                        var data = JSON.parse(res.data[0].data.replace(/\r?\n|\r/g, " "));
                                        let cords
                                        if(data.ptoY && data.ptoX){
                                            cords = { lat: data.ptoY, lng: data.ptoX }
                                        } else {
                                            cords = { lat: 0, lng: 0 }
                                        }
                                        var infraccion = JSON.parse(res.data[0].infraccion.replace(/\r?\n|\r/g, " "));
                                        this.setState({ datosActa: res.data[0], paso: "2", error: "", data: data, infraccion: infraccion, cords: cords });
                                        this.getInfractor(JSON.parse(res.data[0].infractor)[0]);
                                        */
                                    } else {
                                        this.setState({ error: "Acta no encontrada. ¿Seguro que hubó secuestro?" });
                                    }
                                });
                        } else {
                            console.log(res1)
                            console.log("Repetido...")
                            this.setState({ 'cargando': 0, 'repetido': true })
                            //Si entro por aca, es porque ese numero de acta ya esta guardada en la base de datos, ERROR!!!
                            this.setState({ error: "Este número de acta ya fue ingresado al corralón. Buscá por número de acta para ver el vehículo ingresado." });
                        }
                    })
            } else {
                this.setState({ error: "El id de acta debe ser un número entero" });
            }
        } else {
            this.setState({ error: "Debe ingresar un número de acta para continuar" });
        }
    }
    segundoPasoSubmit(event) {
        event.preventDefault();
        //Antes de revisar los datos (paso 3) vamos a checkear la fecha del ingreso y el sector
        this.setState({ 'cargando': 1 })
        if (!Validator.isEmpty(this.state.sector)) {
            if (!Validator.isEmpty(this.state.fechahora)) {
                if (!Validator.isEmpty(this.state.inventario)) {
                    axios.get("/api/secuestros/buscar/inventario/" + this.state.inventario, { headers: { 'token': localStorage.getItem('jwtToken') } })
                        .then(
                            res => {
                                this.setState({ 'cargando': 0 })
                                if (!res.data.error) {
                                    this.setState({ error: "Ya existe un vehículo secuestrado con ese número de inventario. Buscá por número de inventario para ver el vehículo que ingresaron." });
                                } else {
                                    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
                                    if (navigator.getUserMedia) {
                                        navigator.getUserMedia({ video: true }, function () { console.log("GET VIDEO MEDIA OK....") });
                                    }
                                    this.setState({ paso: "3", error: "" });
                                }
                            }
                        )
                } else {
                    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
                    if (navigator.getUserMedia) {
                        navigator.getUserMedia({ video: true }, function () { console.log("GET VIDEO MEDIA OK....") });
                    }
                    this.setState({ paso: "3", error: "" });
                }
            } else {
                this.setState({ error: "Debe ingresar una fecha/hora" });
            }
        } else {
            this.setState({ error: "Debe ingresar un sector" });
        }
        this.setState({ 'cargando': 0 })
    }
    getInfractor(infractor) {
        var datosInfractor;
        if (infractor.id === '127' && infractor.ncNombre && infractor.ncDni && infractor.sexo) {
            var cuil = this.sacarCuil(infractor.ncDni, infractor.sexo)
            datosInfractor = { apynom: infractor.ncNombre, dni: infractor.ncDni, sexo: infractor.sexo, cuil: cuil };
            this.setState({ infractor: datosInfractor });
        } else if (infractor.id === '127' && !infractor.ncNombre && !infractor.ncDni && !infractor.sexo) {
            datosInfractor = {};
            this.setState({ infractor: datosInfractor });
        } else if(infractor.id === '-1') {
            datosInfractor = {};
            this.setState({ infractor: datosInfractor });
        } else {
            this.setState({ 'cargando': 1 })
            axios.get('https://actas.movisn.com/transito/infractorById/' + infractor.id).then(
                res => {
                    this.setState({ 'cargando': 0 })
                    var datos = res.data[0];
                    datosInfractor = { apynom: datos.apelnmb, dni: datos.nrodoc, sexo: datos.sexo, cuil: datos.cuilcuit };
                    this.setState({ infractor: datosInfractor });
                }
            );
        }
    }
    tercerPasoSubmit(event) {
        event.preventDefault();
        if (!Validator.isEmpty(this.state.foto)) {
            this.setState({ paso: "4" })
        } else {
            alert("Por favor tome una foto del vehículo.")
        }
    }
    cuartoPasoSubmit(event) {
        event.preventDefault();
        var nuevoSecuestro = {
            detalles: {
                inventario: this.state.inventario,
                ingreso: this.state.fechahora,
                sector: this.state.sector
            },
            infractor: this.state.infractor,
            acta: {
                nro: this.state.datosActa.nroActa,
                inspector: this.state.datosActa.inspector,
                lugar: this.state.datosActa.lugar,
                fecha_hora: this.state.datosActa.fecha,
                cords: { type: "Point", coordinates: [this.state.cords.lng, this.state.cords.lat] },
            },
            infraccion: this.state.infraccion,
            vehiculo: {
                dominio: this.state.data.dominio,
                tipovh: this.state.data.tipovh,
                marcavh: this.state.data.marcavh,
                modelovh: this.state.data.modelovh
            },
            foto: this.state.foto
        }
        this.setState({ 'cargando': 1 })
        Axios.post('/api/secuestros/nuevo', nuevoSecuestro, { headers: { 'token': localStorage.getItem('jwtToken') } })
            .then(
                res => {
                    this.setState({ 'cargando': 0 })
                    if (!res.data.errrores) {
                        this.props.getSecuestros()
                        this.setState({ paso: "5", id: res.data._id})
                    }
                }
            ).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    alert(error.response.data + " " + error.response.status + " " + error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    alert(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    alert(error.message);
                }
                this.setState({ 'cargando': 0 })
            })
    }
    imprimir() {
        let url = ''
        if (!this.state.inventario) {
            if(!this.state.idacta){
                //No hay acta ni inventario
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.id}/0.pdf`;
            } else {
                //Si hay acta
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.id}/${this.state.idacta}.pdf`;
            }
        } else {
            if(!this.state.idacta){
                //Hay inventario pero no acta
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.id}/0/${this.state.inventario}.pdf`;
            } else {
                //Hay inventario y acta
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.id}/${this.state.idacta}/${this.state.inventario}.pdf`;
            }
        }
        this.setState({ 'cargando': 1 })
        Axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            this.setState({ 'cargando': 0 })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.state.id}.pdf`);
            document.body.appendChild(link);
            link.click();
          });
    }
    onTakePhoto(dataUri) {
        this.setState({ foto: dataUri });
    }
    sacarOtraFoto() {
        this.setState({ foto: '' })
    }
    //GMAPS FUNCTIONS
    onMapCreated(map) {
        map.setOptions({
            disableDefaultUI: true
        });
    }

    onDragEnd(e) {
        console.log('onDragEnd', e);
    }

    onCloseClick() {
        console.log('onCloseClick');
    }

    onClick(e) {
        console.log('onClick', e);
    }
    render() {
        const { cargando, paso, error, data, infraccion, infractor, cords, sectores } = this.state;
        const gmapsparams = { v: '3.exp', key: 'AIzaSyAbtJlesbIaoRiEbLzoTHGH0MHmw0nuV2M' };
        const sectoresOptions = sectores.map(sector => ({
            key: sector,
            text: sector,
            value: sector,
        }))
        return (
            <div>
            { cargando === 1 ? 
                <Message icon>
                    <Icon name='circle notched' loading />
                    <Message.Content>
                        <Message.Header>Cargando...</Message.Header>
                        Recuperando datos del secuestro
                    </Message.Content>
                </Message>
            :
            <Grid stackable centered>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Card fluid color="yellow" attached>
                            <Card.Content>
                                <Card.Header content='Nuevo ingreso' />
                                <Card.Meta content='Ingresa un nuevo vehículo al corralón' />
                                <Card.Description>
                                    <Step.Group stackable='tablet' fluid attached="top">
                                        <Step active={paso === '1'}>
                                            <Icon name='file alternate' />
                                            <Step.Content>
                                                <Step.Title>Acta</Step.Title>
                                                <Step.Description>Ingrese el número de acta</Step.Description>
                                            </Step.Content>
                                        </Step>
                                        <Step active={paso === '2'}>
                                            <Icon name='th' />
                                            <Step.Content>
                                                <Step.Title>Detalles</Step.Title>
                                                <Step.Description>Llene los ultimos detalles</Step.Description>
                                            </Step.Content>
                                        </Step>
                                        <Step active={paso === '3'}>
                                            <Icon name='photo' />
                                            <Step.Content>
                                                <Step.Title>Foto</Step.Title>
                                                <Step.Description>Tome una foto del vehículo</Step.Description>
                                            </Step.Content>
                                        </Step>
                                        <Step active={paso === '4'}>
                                            <Icon name='search' />
                                            <Step.Content>
                                                <Step.Title>Revise los datos</Step.Title>
                                            </Step.Content>
                                        </Step>
                                        <Step active={paso === '5'}>
                                            <Icon name='print' />
                                            <Step.Content>
                                                <Step.Title>Imprima el código</Step.Title>
                                            </Step.Content>
                                        </Step>
                                    </Step.Group>
                                    {paso === '1' &&
                                        <Segment attached>
                                            {error &&
                                                <Message warning icon>
                                                    <Icon name='warning' />
                                                    <Message.Content>
                                                        <Message.Header>Error</Message.Header>
                                                        <p>{this.state.error}</p>
                                                        <Button basic color='red' onClick={() => this.buscarActa()}>Ver</Button>
                                                    </Message.Content>
                                                </Message>
                                            }
                                            <Form>
                                                <Form.Field>
                                                    <Form.Input label='N° acta:' type='input' value={this.state.idacta} onChange={this.handleChange} id="idacta" />
                                                    {cargando ?
                                                        <Form.Button loading basic>Cargando...</Form.Button>
                                                        :
                                                        <Form.Button basic onClick={this.primerPasoSubmit}>Buscar acta</Form.Button>
                                                    }
                                                </Form.Field>
                                            </Form>
                                        </Segment>
                                    }
                                    {paso === '2' &&
                                        <Segment attached>
                                            {error &&
                                                <Message warning icon>
                                                    <Icon name='warning' />
                                                    <Message.Content>
                                                        <Message.Header>Error</Message.Header>
                                                        {this.state.error}
                                                    </Message.Content>
                                                </Message>
                                            }
                                            <Form>
                                                <Form.Field>
                                                    <Select label="Sector" placeholder='Seleccione sector' options={sectoresOptions} value={this.state.sector} onChange={this.handleDropdownChange} name="sector" id="sector" />
                                                    <Form.Input label="N° Inventario (si corresponde)" value={this.state.inventario} onChange={this.handleChange} id="inventario" />
                                                    <Form.Input label='Fecha y hora de ingreso' type='datetime-local' value={this.state.fechahora} onChange={this.handleChange} id="fechahora" />
                                                    {cargando ?
                                                        <Form.Button loading basic>Cargando...</Form.Button>
                                                        :
                                                        <Form.Button basic onClick={this.segundoPasoSubmit}>Confirmar detalles</Form.Button>
                                                    }
                                                </Form.Field>
                                            </Form>
                                        </Segment>
                                    }
                                    {paso === '3' &&
                                        <Segment attached>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        {this.state.foto ?
                                                            <Image src={this.state.foto} onClick={this.sacarOtraFoto} />
                                                            :
                                                            <Camera
                                                                onTakePhoto={(dataUri) => { this.onTakePhoto(dataUri); }}
                                                                isImageMirror={false}
                                                                imageType = {IMAGE_TYPES.JPG}
                                                                imageCompression = {0.65}
                                                                idealResolution={{ width: 640, height: 640 }}
                                                                idealFacingMode= {FACING_MODES.ENVIRONMENT} />
                                                        }
                                                        <Form.Button basic onClick={this.tercerPasoSubmit}>Confirmar foto</Form.Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    }
                                    {paso === '4' &&
                                        <Segment attached>
                                            {cords.lat !== 0 &&
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Gmaps
                                                            width={'100%'}
                                                            height={'250px'}
                                                            lat={cords.lat}
                                                            lng={cords.lng}
                                                            zoom={18}
                                                            loadingMessage={'Cargando...'}
                                                            params={gmapsparams}
                                                            onMapCreated={this.onMapCreated}>
                                                            <Marker
                                                                lat={cords.lat}
                                                                lng={cords.lng}
                                                                draggable={true}
                                                                onDragEnd={this.onDragEnd} />
                                                            <InfoWindow
                                                                lat={cords.lat}
                                                                lng={cords.lng}
                                                                content={'Lugar de secuestro'}
                                                                onCloseClick={this.onCloseClick} />
                                                            <Circle
                                                                lat={cords.lat}
                                                                lng={cords.lng}
                                                                radius={15}
                                                                onClick={this.onClick} />
                                                        </Gmaps>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            }
                                            <Grid columns={2} stackable>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Header as='h2'>Acta</Header>
                                                        <b>N°:</b> {this.state.datosActa.nroActa} <br></br>
                                                        <b>Inspector:</b> {this.state.datosActa.inspector}<br></br>
                                                        <b>Lugar:</b> {this.state.datosActa.lugar}<br></br>
                                                        <b>Fecha / hora:</b> {this.state.datosActa.fecha}

                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <Header as='h2'>Vehículo</Header>
                                                        <b>Dominio:</b> {data.dominio.toUpperCase()}<br></br>
                                                        <b>Tipo vehículo:</b> {data.tipovh}<br></br>
                                                        <b>Marca:</b> {data.marcavh}<br></br>
                                                        <b>Modelo:</b> {data.modelovh}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Divider vertical></Divider>
                                                    <Grid.Column>
                                                        <Header as='h2'>Infractor</Header>
                                                        <b>Nombre:</b> {infractor.apynom}<br></br>
                                                        <b>DNI:</b> {infractor.dni}<br></br>
                                                        <b>Sexo:</b> {infractor.sexo}<br></br>
                                                        <b>CUIL:</b> {infractor.cuil}<br></br>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <Header as='h2'>Infracción</Header>
                                                        <b>Infracción/es:</b> {infraccion.infracciones}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns='1'>
                                                    <Grid.Column>
                                                        {cargando ?
                                                            <Form.Button loading basic>Cargando...</Form.Button>
                                                            :
                                                            <Form.Button basic onClick={this.cuartoPasoSubmit}>Guardar e imprimir código</Form.Button>
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    }
                                    {paso === '5' &&
                                        <Segment>
                                            <Form.Button onClick={this.imprimir}>Imprimir QR</Form.Button>
                                        </Segment>
                                    }
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>}
            </div>
        )
    }
}
IngresarVehiculo.propTypes = {
    getSecuestros: PropTypes.func.isRequired
};


export default connect(
    null,
    { getSecuestros }
)(IngresarVehiculo);