import { GET_SECUESTROS, GET_TOTAL } from "../types";
import Axios from "axios";

export const nuevoSecuestro = (nuevoSecuestro, history) => dispatch => {
    Axios
    .post('/api/secuestros/nuevo', nuevoSecuestro)
    .then(res => history.push("/dashboard"))
};

export const getSecuestros = (pagina) => dispatch => {
    dispatch({type:"SECUESTROS_START"})
    Axios.get('/api/secuestros/todos?page='+pagina)
    .then(res => {
        dispatch({
            type: GET_SECUESTROS,
            payload: res.data.todos
          })
        dispatch({type: GET_TOTAL, payload: res.data.total})
        dispatch({type:"SECUESTROS_END"})
        }
    )
};