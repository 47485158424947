import React, { Component } from 'react'
import Axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Segment, Select, Grid, Card, Message, Icon, Table, Image, Button, Modal, Header, Form } from 'semantic-ui-react'
const Validator = require("validator");
const moment = require('moment')
class VerVehiculo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: null,
            secuestro: null,
            modal: false,
            modal2: false,
            modal3: false,
            cargando: 0,
            cambiarSector: 0,
            nuevoSector: '',
            egreso: {
                apynom: "",
                dni: "",
                domicilio: "",
                licencia: "",
                tarjetaVerde: "",
                bPago: "",
                fechaHora: "",
                obs: "",
                firma: ""
            },
            sectores: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        }
        this.imprimir = this.imprimir.bind(this);
        this.abrirModal = this.abrirModal.bind(this);
        this.abrirModal2 = this.abrirModal2.bind(this);
        this.cerrarModal = this.cerrarModal.bind(this);
        this.cerrarModal2 = this.cerrarModal2.bind(this);
        this.cerrarModal3 = this.cerrarModal3.bind(this);
        this.abrirModal3 = this.abrirModal3.bind(this);
        this.egresar = this.egresar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.cambiarSector = this.cambiarSector.bind(this);
        this.cambiarSector = this.cambiarSector.bind(this);
        this.volver = this.volver.bind(this);
    }
    componentDidMount() {
        this.setState({ secuestro: this.props.location.state.secuestro })
        this.setState({ activePage: this.props.location.state.activePage })
    }
    handleDropdownChange = (e, { name, value }) => this.setState({ [name]: value })
    msAFecha(ms) {
        return moment(ms).format('DD-MM-YYYY HH:mm')
    }
    imprimir() {
        let url = ''
        if (!this.state.secuestro.inventario) {
            if(!this.state.secuestro.acta){
                //No hay acta ni inventario
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.secuestro._id}/0.pdf`;
            } else {
                //Si hay acta
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.secuestro._id}/${this.state.secuestro.acta.nro}.pdf`;
            }
        } else {
            if(!this.state.secuestro.acta){
                //Hay inventario pero no acta
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.secuestro._id}/0/${this.state.secuestro.inventario}.pdf`;
            } else {
                //Hay inventario y acta
                url = `https://corralon.movisn.com/api/secuestros/qr/${this.state.secuestro._id}/${this.state.secuestro.acta.nro}/${this.state.secuestro.inventario}.pdf`;
            }
        }
        this.setState({ 'cargando': 1 })
        Axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            this.setState({ 'cargando': 0 })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.state.secuestro._id}.pdf`);
            document.body.appendChild(link);
            link.click();
          });
    }
    cerrarModal() {
        this.setState({ modal: false })
    }
    abrirModal() {
        this.setState({ modal: true })
    }
    cerrarModal2() {
        this.setState({ modal2: false })
    }
    abrirModal2() {
        this.setState({ modal2: true })
    }
    abrirModal3() {
        this.setState({ modal3: true })
    }
    cerrarModal3() {
        this.setState({ modal3: false })
    }
    cambiarSector(cual) {
        if (cual === 1) {
            this.setState({ cambiarSector: 1 })
        } else {
            this.setState({ cambiarSector: 0, nuevoSector: '' })
        }
    }
    guardarSector() {
        var nuevoSector = this.state.nuevoSector;
        if (!Validator.isEmpty(nuevoSector)) {
            var seguro = window.confirm("¿Seguro desea cambiar el sector a " + this.state.nuevoSector + "?");
            if (seguro) {
                this.setState({ cargando: 1 })
                var params = { id: this.state.secuestro._id, nuevoSector: nuevoSector }
                Axios.post('/api/secuestros/guardarSector', params, { headers: { 'token': localStorage.getItem('jwtToken') } })
                    .then(resp => {
                        if (resp.data.status === 1) {
                            this.setState(prevState => ({
                                secuestro: {
                                    ...prevState.secuestro,
                                    sector: nuevoSector
                                }, cargando: 0, cambiarSector: 0, nuevoSector: ''
                            }))
                        } else {
                            alert("Ocurrió un error guardando el nuevo sector.")
                        }
                    })
            }
        } else {
            alert("Por favor seleccione un sector antes de guardar el nuevo sector.")
        }
    }
    egresar(userId) {
        if (!this.state.secuestro.egreso) {
            if (!Validator.isEmpty(this.state.egreso.apynom) && !Validator.isEmpty(this.state.egreso.dni) && !Validator.isEmpty(this.state.egreso.fechaHora)) {
                this.setState({ cargando: 1 })
                var egresarSecuestro = { idSecuestro: this.state.secuestro._id, egreso: this.state.egreso, userId: userId }
                Axios.post('/api/secuestros/egresar', egresarSecuestro, { headers: { 'token': localStorage.getItem('jwtToken') } })
                    .then(
                        res => {
                            this.setState({ cargando: 0 })
                            if (res.data.success) {
                                alert(res.data.success)
                                this.cerrarModal()
                                Axios.get('/api/secuestros/buscar/id/' + this.state.secuestro._id, { headers: { 'token': localStorage.getItem('jwtToken') } })
                                    .then(
                                        res => {
                                            this.setState({ secuestro: res.data })
                                        }
                                    )
                            } else {
                                alert(res.data.error)
                            }
                        }
                    )
            } else {
                alert("Por favor, ingrese un nombre, dni y una fecha hora de egreso.")
            }
        } else {
            alert("Error: este vehículo ya tiene fecha de egreso.")
        }
    }
    aCompactar(usuario) {
        //Funcion para mandar a compactar
        var seguro = window.confirm("¿Seguro desea marcar este vehículo como compactado?")
        if (seguro) {
            this.setState({ cargando: 1 })
            Axios.post("/api/secuestros/acompactar", { idSecuestro: this.state.secuestro._id, userId: usuario }, { headers: { 'token': localStorage.getItem('jwtToken') } })
                .then(res => {
                    alert(res.data.success)
                    Axios.get('/api/secuestros/buscar/id/' + this.state.secuestro._id, { headers: { 'token': localStorage.getItem('jwtToken') } })
                        .then(
                            res => {
                                this.setState({ secuestro: res.data, cargando: 0 })
                            }
                        )
                })
        }
    }
    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
        console.log(event.target.value.toString())
    }
    handleChange2(event) {
        event.persist()
        this.setState(prevState => ({
            egreso: {
                ...prevState.egreso,
                [event.target.id]: event.target.value.toString()
            }
        }));
        console.log(event.target.value.toString())
        console.log(this.state.egreso)
    }
    volver() {
        if(this.state.activePage){
            //Se en que pagina estabas
            this.props.history.push({pathname: "/dashboard", state:{ activePage: this.state.activePage }})
        } else {
            //No se donde andabas...
            this.props.history.goBack();
        }
    }
    eliminarVehiculo(usuario) {
        //Funcion para mandar a compactar
        var seguro = window.confirm("¿Seguro desea borrar este secuestro?")
        if (seguro) {
            this.setState({ cargando: 1 })
            Axios.post("/api/secuestros/eliminar", { idSecuestro: this.state.secuestro._id, userId: usuario }, { headers: { 'token': localStorage.getItem('jwtToken') } })
                .then(res => {
                    alert(res.data.success)
                    this.volver()
                })
        }
    }
    render() {
        const { secuestro, cargando, cambiarSector, sectores } = this.state
        const { user } = this.props.auth;
        const sectoresOptions = sectores.map(sector => ({
            key: sector,
            text: sector,
            value: sector,
        }))
        const menorA = moment().format('x') - 15778476000
        //Para saber si esta hace mas de 6 meses ingresado, hago secuestro.ingreso < menorA
        return (
            <div>
                {secuestro && cargando !== 1 ?
                    <div>
                        <Grid stackable centered>
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <Card fluid color="yellow" attached='true'>
                                        <Card.Content>
                                            <Card.Header>
                                                <Button.Group floated="left">
                                                    <Button fluid onClick={this.volver}><Icon name='arrow left' /></Button>
                                                </Button.Group>
                                                <Segment compact>{!Validator.isEmpty(secuestro.vehiculo.tipovh)?secuestro.vehiculo.tipovh:''} {!Validator.isEmpty(secuestro.vehiculo.dominio)?secuestro.vehiculo.dominio.toUpperCase():'Sin dominio'}</Segment>
                                                <Button.Group floated="right">
                                                    <Button basic color="blue" onClick={this.imprimir}><Icon name='qrcode' />Imprimir QR</Button>
                                                    {secuestro.egreso ?
                                                        <Button basic color="blue" onClick={() => this.abrirModal2()}><Icon name='sign-out' />Ver Egreso</Button>
                                                        :
                                                        secuestro.compactado ?
                                                            <Button color="grey" disabled='true'><Icon name='sign-out' />Egreso</Button>
                                                            :
                                                            <Button basic color="red" onClick={() => this.abrirModal()}><Icon name='sign-out' />Egreso</Button>
                                                    }
                                                    {(secuestro.ingreso < menorA) && !secuestro.compactado &&
                                                        <Button basic color="red" onClick={() => this.aCompactar(user.id)}>A compactar</Button>
                                                    }
                                                    {(secuestro.ingreso < menorA) && secuestro.compactado &&
                                                        <Button basic color="blue" onClick={() => this.abrirModal3()}>Ver compactado</Button>
                                                    }
                                                    {user.dni === '40499250' &&
                                                        <Button basic color="blue" onClick={() => this.eliminarVehiculo(user.id)}><Icon name='trash' /></Button>
                                                    }
                                                </Button.Group>
                                            </Card.Header>
                                            <Card.Description>
                                                <Image bordered size='medium' src={'/api'+secuestro.foto.replace('.png', '.jpg')} />
                                                <Table celled striped>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell colSpan='2'>Secuestro</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell collapsing>
                                                                Sector
                                                                </Table.Cell>
                                                            {cambiarSector ?
                                                                <Table.Cell>
                                                                    <Select label="Sector" placeholder='Seleccione sector' options={sectoresOptions} value={this.state.sector} onChange={this.handleDropdownChange} name="nuevoSector" id="nuevoSector" />
                                                                    <Button.Group>
                                                                        <Button positive onClick={() => this.guardarSector()}>Guardar</Button>
                                                                        <Button negative onClick={() => this.cambiarSector(0)}>Cancelar</Button>
                                                                    </Button.Group>
                                                                </Table.Cell>
                                                                :
                                                                <Table.Cell>{secuestro.sector} <Button floated='right' size='mini' color='red' onClick={() => this.cambiarSector(1)}>Cambiar sector</Button></Table.Cell>
                                                            }
                                                        </Table.Row>
                                                        {secuestro.inventario && <Table.Row>
                                                            <Table.Cell>
                                                                Inventario
                                                            </Table.Cell>
                                                            <Table.Cell>{secuestro.inventario}</Table.Cell>
                                                        </Table.Row>
                                                        }
                                                    </Table.Body>
                                                </Table>
                                                {secuestro.acta ? <Table celled striped>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell colSpan='2'>Acta</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell collapsing>
                                                                Nro.
                                                            </Table.Cell>
                                                            <Table.Cell>{secuestro.acta.nro}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                Lugar
                                                            </Table.Cell>
                                                            <Table.Cell>{secuestro.acta.lugar}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                Inspector
                                                            </Table.Cell>
                                                            <Table.Cell>{secuestro.acta.inspector}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                Fecha y hora
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {this.msAFecha(secuestro.acta.fecha_hora)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>:''}
                                                <Table celled striped>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell colSpan='2'>Vehículo</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell collapsing>
                                                                Dominio
                                                            </Table.Cell>
                                                            <Table.Cell>{secuestro.vehiculo.dominio?secuestro.vehiculo.dominio.toUpperCase():'Sin dominio'}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                Tipo
                                                            </Table.Cell>
                                                            <Table.Cell>{secuestro.vehiculo.tipovh}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                Marca
                                                            </Table.Cell>
                                                            <Table.Cell>{secuestro.vehiculo.marcavh}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>
                                                                Modelo
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {secuestro.vehiculo.modelovh}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                                {secuestro.infractor &&
                                                    <Table celled striped>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell colSpan='2'>Infractor</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            <Table.Row>
                                                                <Table.Cell collapsing>
                                                                    Apellido y nombres
                                                            </Table.Cell>
                                                                <Table.Cell>{secuestro.infractor.apynom}</Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    DNI
                                                            </Table.Cell>
                                                                <Table.Cell>{secuestro.infractor.dni}</Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    Sexo
                                                            </Table.Cell>
                                                                <Table.Cell>{secuestro.infractor.sexo}</Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    CUIL
                                                            </Table.Cell>
                                                                <Table.Cell>
                                                                    {secuestro.infractor.cuil}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>
                                                }
                                                <Table celled striped>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell colSpan='2'>Infracción/es</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {secuestro.infraccion.length > 0 && secuestro.infraccion.map(infraccion => infraccion.infraccion != null ? (
                                                            <Table.Row key={infraccion._id}>
                                                                <Table.Cell collapsing>
                                                                    Infracción {infraccion.infraccion.digesto}
                                                                </Table.Cell>
                                                                <Table.Cell>{infraccion.infraccion.descrip}</Table.Cell>
                                                            </Table.Row>)
                                                            :
                                                            (<Table.Row>
                                                                <Table.Cell collapsing>
                                                                    Infracción ...
                                                                </Table.Cell>
                                                                <Table.Cell>...</Table.Cell>
                                                            </Table.Row>
                                                            )
                                                        )
                                                        }
                                                    </Table.Body>
                                                </Table>
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {secuestro.compactado &&
                            <Modal
                                open={this.state.modal3}
                                onClose={this.cerrarModal3}
                                size='fullscreen'
                                centered={false}
                                closeIcon>
                                <Header icon='sign-out' content='Ver compactado de vehículo' />
                                <Modal.Content scrolling>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Inspector</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Apellido y nombres
                                        </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.compactado.inspector.apynom}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    DNI
                                        </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.compactado.inspector.dni}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <Table celled striped>
                                    <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Marcado como compactado</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Fecha y hora
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {this.msAFecha(secuestro.compactado.fechaHora)}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button color='red' onClick={this.cerrarModal3} inverted>
                                        <Icon name='remove' /> Cerrar
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        }
                        {secuestro.egreso &&
                            <Modal
                                open={this.state.modal2}
                                onClose={this.cerrarModal2}
                                size='fullscreen'
                                centered={false}
                                closeIcon>
                                <Header icon='sign-out' content='Ver egreso de vehículo' />
                                <Modal.Content scrolling>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Inspector</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Apellido y nombres
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.inspector.apynom}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    DNI
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.inspector.dni}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Persona que retiró el vehículo</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Apellido y nombres
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.apynom}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    DNI
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.dni}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Domicilio
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.domicilio}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Licencia
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.licencia}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Tarjeta verde
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.tarjetaVerde}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Boleta de Pago
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.bPago}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Observaciones
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {secuestro.egreso.obs}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Fecha y hora
                                            </Table.Cell>
                                                <Table.Cell>
                                                    {this.msAFecha(secuestro.egreso.fechaHora)}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button color='red' onClick={this.cerrarModal2} inverted>
                                        <Icon name='remove' /> Cerrar
                            </Button>
                                </Modal.Actions>
                            </Modal>
                        }
                        <Modal
                            open={this.state.modal}
                            onClose={this.cerrarModal}
                            size='fullscreen'
                            centered={false}
                            closeIcon>
                            <Header icon='sign-out' content='Egreso de vehículo' />
                            <Modal.Content scrolling>
                                <Form>
                                    <Form.Field>
                                        <Form.Input label='Apellido y nombres' type='text' onChange={this.handleChange2} id="apynom" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label='DNI' type='text' onChange={this.handleChange2} id="dni" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label='Domicilio' type='text' value={this.state.egreso.domicilio} onChange={this.handleChange2} id="domicilio" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label='Lic. Conducir' type='text' value={this.state.egreso.licencia} onChange={this.handleChange2} id="licencia" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label='Tarj. Verde' type='text' value={this.state.egreso.tarjetaVerde} onChange={this.handleChange2} id="tarjetaVerde" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label='B. Pago' type='text' value={this.state.egreso.bPago} onChange={this.handleChange2} id="bPago" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label='Observaciones' type='text' value={this.state.egreso.obs} onChange={this.handleChange2} id="obs" />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label='Fecha y hora de salida' type='datetime-local' value={this.state.egreso.fechaHora} onChange={this.handleChange2} id="fechaHora" />
                                    </Form.Field>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                {cargando === 1 ?
                                    <Button loading basic>Guardando egreso, por favor espere...</Button>
                                    :
                                    <Button basic onClick={() => { this.egresar(user.id) }}>Egresar vehículo</Button>
                                }
                                <Button color='red' onClick={this.cerrarModal} inverted>
                                    <Icon name='remove' /> Cerrar
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </div>
                    :
                    <div>
                        <Message icon>
                            <Icon name='circle notched' loading />
                            <Message.Content>
                                <Message.Header>Cargando...</Message.Header>
                                Recuperando datos del secuestro
                            </Message.Content>
                        </Message>
                    </div>
                }
            </div>
        )
    }
}

VerVehiculo.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = ({ auth }) => ({
    auth
});

export default connect(
    mapStateToProps,
    {}
)(VerVehiculo);