import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import { Grid } from 'semantic-ui-react';
import Axios from "axios";
class LeerQr extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delay: 100,
      result: 'Sin resultado aún',
    }
    this.handleScan = this.handleScan.bind(this)
  }
  componentDidMount() {
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        {
          video: true
        },
        function (localMediaStream) { },
        function (err) {
          alert('Ocurrió un error al intentar acceder a su cámara: ' + err);
        }
      );
    } else {
      alert('Disculpe, al parecer el navegador no admite acceso a la cámara, revise los permisos del navegador.');
    }
  }

  handleScan(data) {
    if (data) {
      this.setState({
        result: 'Desencriptando codigo ' + data + ' y buscando información de secuestro.',
      })
      Axios.post('/api/secuestros/decrypt', { encriptado: data }, { headers: { 'token': localStorage.getItem('jwtToken') } })
        .then(res => {
          if (!res.data.error) {
            this.props.history.push({ pathname: "/ver", state: { secuestro: res.data } })
          } else {
            alert(res.data.error)
          }
        })
    }
  }

  handleError(err) {
    console.error(err)
  }

  render() {
    return (
      <div>
        <Grid padded stackable centered>
          <Grid.Row>
            <Grid.Column centered>
              <QrReader
                delay={this.state.delay}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: '50%' }}
                facingMode={'environment'}
                ref={(stream) => { this.videoStream = stream }}
              />
              <p>{this.state.result}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default LeerQr