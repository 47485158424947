import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../authActions";
import classnames from "classnames";
import { Form, Input, Button, Label, Grid, Card } from "semantic-ui-react";

class register extends Component {
  constructor() {
    super();
    this.state = {
      dni: "",
      apynom: "",
      tipo: "",
      password: "",
      password2: "",
      errors: {}
    };
  }

  componentDidMount() {
    // Esta pagina es para registrar usuarios nuevos, un usuario tipo 1 puede usarla
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.tipo !== "1") {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/login");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newUser = {
      dni: this.state.dni,
      apynom: this.state.apynom,
      tipo: this.state.tipo,
      password: this.state.password,
      password2: this.state.password2
    };

    this.props.registerUser(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <Grid style={{ height: "75vh" }} stackable centered>
        <Grid.Row>
          <Grid.Column>
            <Card fluid color="yellow" attached>
              <Card.Content>
                <Card.Header content='Nuevo usuario' />
                <Card.Meta content='Registre un nuevo usuario' />
                <Card.Description>
                  <Form noValidate onSubmit={this.onSubmit}>
                    <div className="input-field col s12">
                      <Label htmlFor="apynom">Nombre y apellido</Label>
                      <Input
                        onChange={this.onChange}
                        value={this.state.apynom}
                        error={errors.apynom}
                        id="apynom"
                        placeholder="Juan Perez"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.apynom
                        })}
                      />
                      {errors.apynom &&
                        <span className="alert alert-danger">{errors.apynom}</span>
                      }
                    </div>
                    <div className="input-field col s12">
                      <Label htmlFor="dni">DNI</Label>
                      <Input
                        onChange={this.onChange}
                        value={this.state.dni}
                        error={errors.dni}
                        id="dni"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.dni
                        })}
                      />
                      {errors.dni &&
                        <span className="alert alert-danger">{errors.dni}</span>
                      }
                    </div>
                    <div className="col-sm-12">
                      <Label htmlFor="tipo">Tipo de usuario</Label>
                      <Input
                        onChange={this.onChange}
                        value={this.state.tipo}
                        error={errors.tipo}
                        id="tipo"
                        type="string"
                        className={classnames("form-control", {
                          invalid: errors.tipo
                        })}
                      />
                      {errors.tipo &&
                        <span className="alert alert-danger">{errors.tipo}</span>
                      }
                    </div>
                    <div className="input-field col s12">
                      <Label htmlFor="password">Contraseña</Label>
                      <Input
                        onChange={this.onChange}
                        value={this.state.password}
                        error={errors.password}
                        id="password"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password
                        })}
                      />
                      {errors.password &&
                        <span className="alert alert-danger">{errors.password}</span>
                      }
                    </div>
                    <div className="input-field col s12">
                      <Label htmlFor="password2">Confirmación de contraseña</Label>
                      <Input
                        onChange={this.onChange}
                        value={this.state.password2}
                        error={errors.password2}
                        id="password2"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password2
                        })}
                      />
                      {errors.password2 &&
                        <span className="alert alert-danger">{errors.password2}</span>
                      }
                    </div>
                    <Button
                      type="submit"
                      className="btn btn-primary col-sm-12"
                    >
                      Registrar usuario
                                        </Button>
                  </Form>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(register));
