import React, { Component } from "react";
import { Form, Message, Header, Icon, Button, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../authActions";
import classnames from "classnames";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      dni: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    console.log(this.props.auth.isAuthenticated);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      dni: this.state.dni,
      password: this.state.password
    };

    this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;

    return (
      <Grid stackable centered>
        <Grid.Row>
          <Grid.Column></Grid.Column>
          <Grid.Column width={10}>
            <Header as='h3' icon textAlign='center'>
              <Icon name='users' circular />
              <Header.Content><b>Ingrese</b> para usar el sistema Corralon SN</Header.Content>
            </Header>
            <Form onSubmit={this.onSubmit}>
              <Form.Field>
                <label>DNI</label>
                <input onChange={this.onChange}
                  value={this.state.dni}
                  error={errors.dni}
                  id="dni"
                  type="number"
                  className={classnames("form-control ", {
                    invalid: errors.dni || errors.dninotfound
                  })}
                  placeholder='00000000' />
              </Form.Field>
              {
                (errors.dni ||
                  errors.dninotfound) &&
                <Message
                  red
                  header='Error'
                  content={errors.dni ||
                    errors.dninotfound}
                />
              }
              <Form.Field>
                <label>Contraseña</label>
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("form-control", {
                    invalid: errors.password || errors.passwordincorrect
                  })}
                  placeholder="******"
                />
              </Form.Field>
              {(errors.password || errors.passwordincorrect) &&
                <Message
                  red
                  header='Error'
                  content={errors.password || errors.passwordincorrect}
                />
              }
              <Button type='submit'>Ingresar</Button>
            </Form>
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
