import React, { Component } from "react";
import { Label, Tab, Table, Header, Modal, Button, Segment, Card, Select, Grid, Form, Checkbox, Item, Statistic, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import Axios from "axios";
import UIMessage from '../../../components/Message';
const moment = require('moment')


class PanelVehiculos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            secuestros: null,
            cargando: false,
            filtrando: false,
            filtros: {
                ingresado: false,
                egresado: false,
                compactado: false,
                desde: null,
                hasta: null,
            },
            infraccion: null,
            infracciones: [],
            estadisticas: {},
            generando: false,
            excel: '',
            generando2: false,
            excel2: '',
            sectores: [],
            modal: false,
            detalle: [],
            buscandoDetalle: false,
            infoDetalle: {actual:{motos: 0, autos:0}, total: {motos:0,autos:0}, historial:{ingresos: [], egresos: [], compactados: []}}
        }
        this.abrirModal = this.abrirModal.bind(this);
        this.cerrarModal = this.cerrarModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.filtrar = this.filtrar.bind(this);
        this.verSecuestro = this.verSecuestro.bind(this);
        this.limpiarFiltros = this.limpiarFiltros.bind(this);
        this.compactar = this.compactar.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }
    handleDropdownChange = (e, { name, value }) => this.setState({ [name]: value })
    async componentDidMount() {
        this.setState({ cargando: true })
        await Axios.post('/api/secuestros/infracciones')
            .then(resp => { this.setState({ infracciones: resp.data.infracciones, estadisticas: resp.data.estadisticas })})
        await Axios.get('/api/secuestros/sectores')
            .then(resp => { this.setState({ sectores: resp.data, cargando: false }) }
            )
    }
    handleChange = (event, { name, value }) => {
        this.setState(prevState => ({ filtros: { ...prevState.filtros, [name]: value } }))
    }
    handleChange2(event) {
        event.persist()
        this.setState(prevState => ({
            filtros: {
                ...prevState.filtros,
                [event.target.id]: event.target.value.toString()
            }
        }));
    }
    checkBoxChange(cual) {
        if (cual === 'ingresado') {
            this.setState(prevState => ({ filtros: { ...prevState.filtros, ingresado: !prevState.filtros.ingresado } }))
        }
        if (cual === 'egresado') {
            this.setState(prevState => ({ filtros: { ...prevState.filtros, egresado: !prevState.filtros.egresado } }))
        }
        if  (cual === 'compactado') {
            this.setState(prevState => ({ filtros: { ...prevState.filtros, compactado: !prevState.filtros.compactado } }))
        }
    }
    msAFecha(ms) {
        return moment(ms).format('DD/MM/YYYY HH:mm')
    }
    filtrar() {
        if (this.state.filtros.desde && this.state.filtros.hasta) {
            if (this.state.filtros.ingresado || this.state.filtros.egresado || this.state.filtros.compactado) {
                var params = { filtros: this.state.filtros, infraccion: this.state.infraccion }
                this.setState({ filtrando: true, secuestors: null })
                var page = this.state.activePage;
                Axios.post("/api/secuestros/filtros?page="+page, params, { headers: { 'token': localStorage.getItem('jwtToken') } })
                    .then(resp => {
                        this.setState({ secuestros: resp.data.todos, totalSecuestros: resp.data.total, filtrando: false})
                    })
                    .catch(err => console.log(err))
            } else {
                alert("Ademas del periodo, tiene que elegir por que estado filtrar, ingresado o egresado.")
            }
        } else {
            alert("Antes de filtrar, seleccione un periodo de tiempo desde hasta.")
        }
    }
    compactar() {
        this.setState({ filtrando: true, secuestros: null }, () => {
            Axios.get("/api/secuestros/compactar", { headers: { 'token': localStorage.getItem('jwtToken') } })
            .then(resp => { 
                this.setState({ secuestros: resp.data.todos, filtrando: false }) 
            })
            .catch(err => console.log(err))
        })
    }
    compactarExcel() {
        this.setState({ generando2: true, excel2: '' }, () => {
            Axios.get("/api/secuestros/excelcompactar")
            .then(resp => { 
                this.setState({ generando2: false, excel2: resp.data })
            })
            .catch(err => console.log(err))
        })
    }
    generarExcel() {
        this.setState({ generando: true, excel: '' }, () => {
            Axios.get("/api/secuestros/excel")
            .then(resp => { 
                this.setState({ generando: false, excel: resp.data })
             })
            .catch(err => console.log(err))
        })
    }
    descargarExcel() {
        window.location.replace('/api'+this.state.excel)
    }
    descargarExcel2() {
        window.location.replace('/api'+this.state.excel2)
    }
    verSecuestro(secuestro) {
        this.props.history.push({ pathname: "/ver", state: { secuestro: secuestro } })
    }
    limpiarFiltros() {
        this.setState({ filtros: { ingresado: false, egresado: false, compactado:null, desde: null, hasta: null }, infraccion: null, secuestros: null })
    }
    cerrarModal() {
        this.setState({ modal: false })
    }
    async abrirModal(sec) {
        this.setState({ modal: true, buscandoDetalle: true })
        await Axios.get("/api/secuestros/sector/"+sec).then(resp => {
            this.setState({buscandoDetalle: false, detalle: resp.data})
            let tMotos = 0
            let tAutos = 0
            let aMotos = 0
            let aAutos = 0
            let historial = {ingresos: [], egresos: [], compactados: []}
            resp.data.forEach(function(sec, ind){
                if(sec.egreso && sec.egreso != null){
                    //Para el total, entró y salió
                    if(sec.inventario != null){
                        tMotos+=1
                    } else {
                        tAutos+=1
                    }
                    historial.egresos.push(sec)
                } else if (sec.compactado && sec.compactado != null){
                    historial.compactados.push(sec)
                    if(sec.inventario != null){
                        tMotos+=1
                    } else {
                        tAutos+=1
                    }
                } else if(!sec.egreso && !sec.compactado){
                    if(sec.inventario != null){
                        aMotos+=1
                        tMotos+=1
                    } else {
                        aAutos+=1
                        tAutos+=1
                    }
                    historial.ingresos.push(sec)
                }
            })
            this.setState(prevState => ({ infoDetalle: { ...prevState.infoDetalle, actual:{motos: aMotos, autos: aAutos}, total:{motos: tMotos, autos: tAutos},  historial: historial } }))
        })
    }
    render() {
        const { infoDetalle, buscandoDetalle, modal, sectores, secuestros, infracciones, cargando, filtrando, estadisticas, generando, excel, excel2, generando2} = this.state
        const infraccionesOptions = infracciones.map(infraccion => ({
            key: infraccion._id,
            text: infraccion.descrip,
            value: infraccion._id,
        }))
        return (
            <div>
                {cargando === true ?
                    <UIMessage tipo='cargando' mensaje='Por favor espere...' />
                    :
                    <Grid padded stackable centered>
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <Segment>
                                    <Statistic.Group size='small'>
                                        <Statistic>
                                            <Statistic.Value>
                                                <Icon name='sign-in' />{estadisticas.ingresos}
                                            </Statistic.Value>
                                            <Statistic.Label>Ingresos</Statistic.Label>
                                        </Statistic>
                                        <Statistic>
                                            <Statistic.Value>
                                                <Icon name='sign-out' />{estadisticas.egresos}
                                            </Statistic.Value>
                                            <Statistic.Label>Egresos</Statistic.Label>
                                        </Statistic>
                                        <Statistic label='Infractores' value={estadisticas.infractores} />
                                        <Statistic label='Sin infractor' value={estadisticas.sinInfractor} />
                                        <Statistic color='red'>
                                            <Statistic.Value>
                                                <Icon name='glass martini' />{estadisticas.alcoholemia}
                                            </Statistic.Value>
                                            <Statistic.Label>Alcoholemia</Statistic.Label>
                                        </Statistic>
                                        <Statistic label='Mal estacionado' value={estadisticas.malEstacionado} />
                                        <Statistic label='Compactado' value={estadisticas.compactado} />
                                        <Statistic label='A compactar' value={estadisticas.aCompactar} />
                                    </Statistic.Group>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column stackable width={15}>
                                <Tab panes={[
                                    { menuItem: 'Filtros', render: () => <Tab.Pane>
                                        <Grid.Column width={15}>
                                        <Card fluid color="yellow" attached>
                                            <Card.Content>
                                                <Card.Header>
                                                <Form.Button floated='right' color='red' onClick={() => this.filtrar()}>Filtrar</Form.Button>
                                                <Form.Button onClick={() => this.limpiarFiltros()} color='blue' floated='right'>Limpiar filtros</Form.Button>
                                                </Card.Header>
                                                <Card.Meta content='Filtre los vehículos según lo que necesite' />
                                                <Card.Description>
                                                    <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={4}>
                                                            <h3>Filtrar por estado</h3>
                                                            <Checkbox label='Ingresado' onChange={() => this.checkBoxChange('ingresado')} checked={this.state.filtros.ingresado} />
                                                            <Checkbox label='Egresado' onChange={() => this.checkBoxChange('egresado')} checked={this.state.filtros.egresado} />
                                                            <Checkbox label='Compactado' onChange={() => this.checkBoxChange('compactado')} checked={this.state.filtros.compactado} />                                            
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <h3>Filtrar por fecha</h3>
                                                            <Form.Group widths='equal'>
                                                                <DateInput
                                                                    placeholder="Fecha desde"
                                                                    value={this.state.filtros.desde}
                                                                    iconPosition='left'
                                                                    onChange={this.handleChange}
                                                                    name='desde' autocomplete="off" hideMobileKeyboard={1}/>
                                                                <DateInput
                                                                    placeholder="Fecha hasta"
                                                                    value={this.state.filtros.hasta}
                                                                    iconPosition='left'
                                                                    onChange={this.handleChange}
                                                                    name='hasta' autocomplete="off" hideMobileKeyboard={1}/>
                                                            </Form.Group>
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <h3>Filtrar por infracción</h3>
                                                            <Select label="Infracción" placeholder='Seleccione infracción de transito' options={infraccionesOptions} value={this.state.infraccion} onChange={this.handleDropdownChange} name="infraccion" id="infraccion" />
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                        <Card fluid color='red'>
                                                        <Card.Content>
                                                            <Card.Header>Para compactar</Card.Header>
                                                            <Card.Meta>Ingresados hace más de 6 meses</Card.Meta>
                                                        </Card.Content>
                                                        <Card.Description>Busca vehículos que han ingresado hace más de 6 meses y no fueron egresados</Card.Description>
                                                        <Card.Content extra><Form.Button onClick={() => this.compactar()} fluid color='red'>Buscar vehículos para compactar</Form.Button></Card.Content>
                                                        </Card>
                                                        </Grid.Column>
                                                        <Grid.Column width={16}>
                                                        {filtrando === true &&
                                                                <UIMessage tipo='cargando' mensaje='Filtrando... por favor espere' />
                                                            }
                                                            {secuestros !== null && secuestros.length > 0 ?
                                                                <div>
                                                                    <h3>Resultados de filtrado, se encontraron {secuestros.length} vehículos.</h3>
                                                                    <Item.Group divided unstackable link>
                                                                        {!filtrando && secuestros.length > 0 && secuestros.map((secuestro) =>
                                                                            <Item onClick={() => this.verSecuestro(secuestro)}>
                                                                                <Item.Image size='tiny' src={'/api'+secuestro.foto.replace('.png', '.jpg')} />
                                                                                <Item.Content>
                                                                                    <Item.Header as='a'>{secuestro.vehiculo.tipovh} {secuestro.vehiculo.dominio?secuestro.vehiculo.dominio.toUpperCase():'Sin dominio'}</Item.Header>
                                                                                    <Item.Meta>Estado: {secuestro.egreso ? <b>Egresado</b> : secuestro.compactado ? <b>Compactado</b>:<b>Ingresado</b>}{secuestro.acta ? <span>N° acta: {secuestro.acta.nro}</span>:''} </Item.Meta>
                                                                                    {secuestro.acta ? <Item.Description>
                                                                                        {secuestro.acta.lugar}
                                                                                    </Item.Description> : ''}
                                                                                    <Item.Extra>{this.msAFecha(secuestro.fecha_hora)}</Item.Extra>
                                                                                </Item.Content>
                                                                            </Item>
                                                                        )
                                                                        }
                                                                    </Item.Group>
                                                                </div>
                                                                :
                                                                !filtrando && <div><h3>Resultados: </h3><h4>Sin resultados, por favor filtre o cambie los parametros de filtrado.</h4></div>
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    </Grid>
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                            </Grid.Column>
                            <Grid.Column width={15}>
                                
                            </Grid.Column>
                                    </Tab.Pane> },
                                    { menuItem: 'Sectores', render: () => <Tab.Pane><Card.Group itemsPerRow={8}>
                                        {sectores.map((sec, i) => 
                                            <Card>                                         
                                            <Card.Content>
                                                <Header as='h1'>{sec.sector}</Header>
                                                <Card.Header>Sector </Card.Header>
                                                <Card.Meta>Detalle</Card.Meta>
                                                <Card.Description>
                                                <strong>Motos: {sec.motos}</strong><br></br>
                                                <strong>Autos: {sec.autos}</strong>
                                                </Card.Description>
                                            </Card.Content>
                                            <Card.Content extra>
                                                <div className='ui two buttons'>
                                                <Button basic color='blue' onClick={() => this.abrirModal(sec.sector)}>
                                                    Ver detalle
                                                </Button>
                                                </div>
                                            </Card.Content>
                                            </Card>
                                            )}
                                        </Card.Group>
                                    </Tab.Pane> },
                                    { menuItem: 'Exportar', render: () => <Tab.Pane>
                                        <Grid.Row>
                                        <Grid.Column>
                                            <Card fluid color="yellow" attached>
                                                <Card.Content>
                                                    <Card.Header>
                                                        Exportar
                                                    </Card.Header>
                                                    <Card.Meta content='Funciones para descargar planillas excel de la base de datos de secuestros.' />
                                                    <Card.Description>
                                                        <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column width={8}>
                                                            <h3>Exportar todo a excel</h3>
                                                            <span>Genera un excel general con todos los secuestros.</span>
                                                            {generando === true ?
                                                            <Form.Button fluid color='blue' loading>Descargar excel</Form.Button>
                                                            :
                                                            <Form.Button onClick={() => this.generarExcel()} fluid color='red'>Generar excel con todos los secuestros</Form.Button>
                                                            }
                                                            {excel !== '' &&
                                                            <Form.Button onClick={() => this.descargarExcel()} fluid color='blue'>Descargar excel</Form.Button>  
                                                            }
                                                            </Grid.Column>
                                                            <Grid.Column width={8}>
                                                            <h3>Exportar vehiculos para compactar</h3>
                                                            <span>Genera un excel con todos los vehiculos que estan para compactar.</span>
                                                            {generando2 === true ?
                                                                <Form.Button fluid color='red' loading>Descargar excel con vehículos para compactar</Form.Button>
                                                            :
                                                                <Form.Button onClick={() => this.compactarExcel()} fluid color='red'>Generar excel con vehículos para compactar</Form.Button>
                                                            }
                                                            {excel2 !== '' &&
                                                                <Form.Button onClick={() => this.descargarExcel2()} fluid color='blue'>Descargar excel</Form.Button>  
                                                            }
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        </Grid>
                                                    </Card.Description>
                                                    </Card.Content>
                                            </Card>
                                        </Grid.Column>
                                        </Grid.Row>
                                    </Tab.Pane> },
                                    ]} />
                                    </Grid.Column></Grid.Row>
                    </Grid>
                }
                            <Modal
                                open={modal}
                                onClose={this.cerrarModal}
                                size='fullscreen'
                                centered={false}
                                closeIcon>
                                <Header icon='sign-out' content='Ver detalle de sector' />
                                <Modal.Content scrolling>
                                {buscandoDetalle === true ?
                                    <UIMessage tipo='cargando' mensaje='Por favor espere...' />
                                    :
                                    <Grid>
                                    <Grid.Column width={8}>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Actualmente</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Motos:
                                                </Table.Cell>
                                                <Table.Cell>
                                                  {infoDetalle.actual.motos}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Autos:
                                                </Table.Cell>
                                                <Table.Cell>
                                                  {infoDetalle.actual.autos}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Total</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Motos:
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {infoDetalle.total.motos}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Autos:
                                                </Table.Cell>
                                                <Table.Cell>
                                                  {infoDetalle.total.autos}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Ingresos</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {infoDetalle.historial.ingresos.length === 0 ?
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Nada para mostrar.
                                                </Table.Cell>
                                            </Table.Row>
                                        :
                                            infoDetalle.historial.ingresos.map(ing => <Table.Row>
                                                <Table.Cell collapsing>
                                                <i aria-hidden="true" class="sign in icon"></i>
                                                </Table.Cell>
                                                <Table.Cell>
                                                <Label>
                                                    {this.msAFecha(ing.fecha_hora)}
                                                </Label>
                                                {ing.vehiculo.dominio?ing.vehiculo.dominio.toUpperCase():'Sin dominio'} {ing.vehiculo.tipovh} {ing.vehiculo.marcavh} {ing.vehiculo.modelovh} 
                                                </Table.Cell>
                                            </Table.Row>)
                                         }
                                        </Table.Body>
                                    </Table>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Egresos</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {infoDetalle.historial.egresos.length === 0 ?
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Nada para mostrar.
                                                </Table.Cell>
                                            </Table.Row>
                                        :
                                            infoDetalle.historial.egresos.map(ing => <Table.Row>
                                                <Table.Cell collapsing>
                                                <i aria-hidden="true" class="sign out icon"></i>
                                                </Table.Cell>
                                                <Table.Cell>
                                                <Label>
                                                {this.msAFecha(ing.egreso.fechaHora)}</Label>  {ing.vehiculo.dominio?ing.vehiculo.dominio.toUpperCase():'Sin dominio'} {ing.vehiculo.tipovh} {ing.vehiculo.marcavh} {ing.vehiculo.modelovh} 
                                                </Table.Cell>
                                            </Table.Row>)
                                         }
                                        </Table.Body>
                                    </Table>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                    <Table celled striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2'>Compactados</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {infoDetalle.historial.compactados.length === 0 ?
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    Nada para mostrar.
                                                </Table.Cell>
                                            </Table.Row>
                                        :
                                            infoDetalle.historial.compactados.map(ing => <Table.Row>
                                                <Table.Cell collapsing>
                                                    <i aria-hidden="true" class="sign out icon"></i>
                                                </Table.Cell>
                                                <Table.Cell>
                                                <Label>{this.msAFecha(ing.compactado.fechaHora)}</Label> {ing.vehiculo.dominio?ing.vehiculo.dominio.toUpperCase():'Sin dominio'} {ing.vehiculo.tipovh} {ing.vehiculo.marcavh} {ing.vehiculo.modelovh} 
                                                </Table.Cell>
                                            </Table.Row>)
                                         }
                                        </Table.Body>
                                    </Table>
                                    </Grid.Column>
                                    </Grid>}
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button color='red' onClick={this.cerrarModal} inverted>
                                        <Icon name='remove' /> Cancelar
                                    </Button>
                                </Modal.Actions>
                            </Modal>
            </div>
        )
    }
}

export default PanelVehiculos;