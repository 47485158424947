import { GET_SECUESTROS, GET_TOTAL, SECUESTROS_START, SECUESTROS_END } from "../types";
const initialState = {
    secuestros:{},
    loading:false,
    total:null
  };
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_SECUESTROS:
        return {
            ...state,
            secuestros: action.payload
        };
      case GET_TOTAL:
        return {
          ...state,
          total: action.payload
        }
      case SECUESTROS_START:
        return {...state, loading:true}
      case SECUESTROS_END:
        return {...state, loading:false}
      default:
        return state;
    }
}