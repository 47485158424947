import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Menu, Icon } from "semantic-ui-react"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../login/authActions";
class Navbar extends Component {
  state = { activeItem: 'inicio' }
  onClickMenu(name, to) {
    this.setState({ activeItem: name });
    this.props.history.push(to);
  }

  handleItemClick = (e, { name, to }) => this.onClickMenu(name, to);


  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
    this.props.history.push("/");
  };
  render() {
    const { user, isAuthenticated } = this.props.auth;
    const { activeItem } = this.state
    return (
      <Menu style={{ backgroundColor: '#FFEF01' }} widths={user.tipo === 1 ? 3 : 4} icon='labeled'>
        <Menu.Item name='inicio' to="/" active={activeItem === 'inicio'} onClick={this.handleItemClick}>
          <Icon name='home' />
          Inicio
          </Menu.Item>
        {isAuthenticated &&
          <Menu.Item
            name='vehiculos'
            to="/vehiculos"
            active={activeItem === 'vehiculos'}
            onClick={this.handleItemClick}
          >
            <Icon name='car' />
            Vehículos
              </Menu.Item>
        }
        {isAuthenticated && user.tipo === '1' &&
          <Menu.Item
            name='usuario'
            to="/usuarios"
            active={activeItem === 'usuarios'}
            onClick={this.handleItemClick}
          >
            <Icon name='user' />
            Usuarios
            </Menu.Item>
        }
        {isAuthenticated &&
          <Menu.Item
            name='salir'
            active={activeItem === 'salir'}
            onClick={this.onLogoutClick}
          >
            <Icon name='sign-out' />
            Salir
              </Menu.Item>
        }
      </Menu>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(
  mapStateToProps,
  { logoutUser }
)(Navbar));