import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { withRouter, Link } from "react-router-dom";
class VehiculosIndex extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.handleClick = this.handleClick.bind(this)
    }
    handleClick(event) {
        this.props.history.push(event.target.to);
    }

    handleItemClick = (e, to) => this.props.history.push(to);
    render() {
        return (
            <div>
                <Link to="/ingreso">
                    <Message
                        icon='sign-in alternate'
                        header='Nuevo ingreso'
                        content='Ingresa un nuevo vehículo al corralón municipal'
                    />
                </Link>
                <br></br>
                <Link to="/leer_qr">
                    <Message
                        icon='qrcode'
                        header='Leer QR'
                        content='Lee los datos de un vehículo y/o lo egresa del corralón municipal'
                    />
                </Link>
                <br></br>
                <Link to="/buscar">
                    <Message
                        icon='file alternate outline'
                        header='Buscar vehículo'
                        content='Busque vehículos por patente, número de acta o de inventario'
                    />
                </Link>
                <br></br>
                    <Link to="/stock">
                        <Message
                            icon='folder alternate outline'
                            header='Panel de Vehículos'
                            content='Filtra los vehículos'
                        />
                    </Link>
            </div>
        );
    }
}
VehiculosIndex.propTypes = {
    auth: PropTypes.object.isRequired
};
const mapStateToProps = ({ auth, secuestros }) => ({
    auth
});
export default connect(
    mapStateToProps,
    {}
)(withRouter(VehiculosIndex));