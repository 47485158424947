import React, { Component } from 'react'
import { Grid, Item } from 'semantic-ui-react';
const moment = require('moment')

class LeerQr extends Component {
  constructor(props) {
    super(props)
    this.state = {
      secuestros: null
    }
    this.verSecuestro = this.verSecuestro.bind(this);
    this.msAFecha = this.msAFecha.bind(this);
  }

  componentDidMount() {
    this.setState({ secuestros: this.props.location.state.secuestros })
  }

  verSecuestro(secuestro) {
    this.props.history.push({ pathname: "/ver", state: { secuestro: secuestro } })
  }

  msAFecha(ms) {
    return moment(ms).format('DD/MM/YYYY HH:mm')
}
  render() {
    const { secuestros } = this.state
    console.log(secuestros)
    return (
      <div>
        <Grid padded stackable centered>
          <Grid.Row>
            <Grid.Column width={15} centered>
              {secuestros !== null && secuestros.length > 0 ?
                <div>
                  <h3>Resultados de búsqueda, {secuestros.length===1? 'se encontró':'se encontraron'} {secuestros.length} {secuestros.length===1? 'vehículo':'vehículos'}.</h3>
                  <Item.Group divided unstackable link>
                    {secuestros.length > 0 && secuestros.map((secuestro) =>
                      <Item onClick={() => this.verSecuestro(secuestro)}>
                        <Item.Image size='tiny' src={'/api' + secuestro.foto.replace('.png', '.jpg')} />
                        <Item.Content>
                          <Item.Header as='a'>{secuestro.vehiculo.tipovh} {secuestro.vehiculo.dominio?secuestro.vehiculo.dominio.toUpperCase():'Sin dominio'}</Item.Header>
                          <Item.Meta>Estado: {secuestro.egreso ? <b>Egresado</b> : secuestro.compactado ? <b>Compactado</b> : <b>Ingresado</b>}{secuestro.acta ? <span>N° acta: {secuestro.acta.nro}</span> : ''} </Item.Meta>
                          {secuestro.acta ? <Item.Description>
                            {secuestro.acta.lugar}
                          </Item.Description> : ''}
                          <Item.Extra>{this.msAFecha(secuestro.fecha_hora)}</Item.Extra>
                        </Item.Content>
                      </Item>
                    )
                    }
                  </Item.Group>
                </div>
                :
                <div><h3>Resultados: </h3><h4>Sin resultados, por favor vuelva a intentarlo, pruebe agregando o sacando espacio al dominio.</h4></div>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

export default LeerQr