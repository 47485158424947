import React from "react";
import { Message, Icon } from 'semantic-ui-react';
const UIMessage = ({ tipo, mensaje }) => (
    <Message icon>
        <Icon name={tipo==='error'?'warning':'circle notched'}  loading={tipo!=='error'} />
        <Message.Content>
            <Message.Header>{tipo==='error'?'Error':'Cargando...'}</Message.Header>
            {mensaje}
        </Message.Content>
    </Message>
)

export default UIMessage;