import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
class Footer extends Component {
    render() {
        return (
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <Image width={150} floated={'right'} src={'/snciudad.svg'}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Footer;